<!--
 * @Description  : 安全设备
-->
<template>
  <div class="hx-main pad-article-content">
    <div class="hx-container pad-article-content">
      <strong class="hx-title" style="font-weight: 500;" v-if="title">
        {{ title }}
      </strong>
      <div class="hx-subtitle" v-if="subTitle">
        {{ subTitle }}
      </div>
      <div class="hx-article" v-html="content">
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { articles, laws } from './../const/articles'
export default {
  data () {
    return {
      content: '',
      title: '',
      subTitle: ''
    }
  },
  mounted () {
    this.$_init()
  },
  methods: {
    $_init () {
      const { id } = this.$route.params
      const { type } = this.$route.query
      const list = this.$_getArray(type)
      const bundle = list[id]
      this.title = bundle.title
      this.subTitle = bundle.subTitle
      axios({
        url: bundle.url,
        method: 'get',
        params: {}
      }).then(res => {
        if (res.status === 200) {
          this.content = res.data
          this.$forceUpdate()
        }
      })
    },
    $_getArray (type) {
      if (type === 'laws') {
        return laws
      } else {
        return articles
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";
.pad-article-content {
  padding-top: @pm-lg * 2;
  .hx-article {
    max-width: 860px;
    margin: 0 auto;
    display: block;
    text-align: justify;
    padding: 0;
    /deep/.row-flex {
      justify-content: center;
      display: flex;
      img {
        margin: 0;
      }
      img + img {
        margin-left: @pm-md;
      }
    }
    /deep/ p { font-size: 16px; line-height: 2; }
    /deep/ h5 { font-size: 18px; margin: 0; }
    /deep/ img {
      display: block;
      margin: @pm-md auto;
      max-width: 100%;
      border-radius: @pm-xs;
    }
    /deep/ ul, /deep/ ol {
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 24px;
      line-height: 1.5;
      font-size: 16px;
      margin: 0;
      &::marker {
        font-weight: 600;
        color: #888;
        background-color: #888;
      }
    }
  }
}
</style>
