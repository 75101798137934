/**
 * 文章列表
 */

const serverPrefix = location.href.includes('anransafety.com')
  ? 'https://api.anransafety.com/'
  : 'https://api.anransafety.com/'

export const articles = {
  1001: { title: 'HSE 管理合规性评估', subTitle: '', url: `${serverPrefix}1001.html` },
  1002: { title: '安全战略性规划与文化建设', subTitle: '', url: `${serverPrefix}1002.html` },
  1003: { title: 'HSE 管理体系建设', subTitle: '', url: `${serverPrefix}1003.html` },
  1004: { title: '双重预防机制', subTitle: '', url: `${serverPrefix}1004.html` },
  1005: { title: '应急预案编制与实战演练', subTitle: '', url: `${serverPrefix}1005.html` },
  1006: { title: '第三方安全检查与隐患排查', subTitle: '', url: `${serverPrefix}1006.html` },
  1007: { title: '安全技术人员驻场服务', subTitle: '', url: `${serverPrefix}1007.html` },
  1008: { title: '定制化安全培训', subTitle: '', url: `${serverPrefix}1008.html` },
  1009: { title: '安全文化活动策划及实施', subTitle: '', url: `${serverPrefix}1009.html` },
  1010: { title: '安全宣教材料设计与制作', subTitle: '', url: `${serverPrefix}1010.html` },
  1011: { title: '安全教育视频、期刊及出版物', subTitle: '', url: `${serverPrefix}1011.html` },
  1012: { title: '自动体外除颤器AED', subTitle: '', url: `${serverPrefix}1012.html` },
  1013: { title: '正压式空气呯吸器', subTitle: '', url: `${serverPrefix}1013.html` },
  1014: { title: '多合一气体检测仪', subTitle: '', url: `${serverPrefix}1014.html` },
  1015: { title: '安全生产标准化', subTitle: '', url: `${serverPrefix}1015.html` },
  1016: { title: 'HAZOP分析与SIL评估', subTitle: '', url: `${serverPrefix}1016.html` }
}

export const laws = {
  1001: { title: '', subTitle: '', url: `${serverPrefix}laws/1001.html` },
  1002: { title: '', subTitle: '', url: `${serverPrefix}laws/1002.html` }
}
